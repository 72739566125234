import { emailLink } from "../utils"

const Contact = () => {
  return <article>
    <p>Please contact us over the <a href={emailLink}>email</a>. 
      We should get back to you soon.
    </p>

    <p>Lonewolf Solutions Mateusz Reszka <br /> 
      Wyzwolenia 32b/5 <br />
      Gdańsk 80-537 <br /> 
      Poland, Pomorskie <br /> 
      Tax ID / NIP: 957-099-86-03 
    </p>
  </article>
}

export default Contact

