import { Header } from "components/header"
import { emailLink } from "utils"
import Router from "./router"

import "./styles/globals.css"

/**
 * Application root. `RouterConfig` renders a content of the page which changes depending on URL.
 */
function App(): JSX.Element {
  return <div id="wrapper" className='container mx-auto flex flex-col lg:max-w-4xl'>
    <Header />

    <hr />

    <main>
      <Router />
    </main>

    <div className='flex-grow'/>

    <hr />

    <footer className='p-4 content-between'>
      <a className='text-slate-500 hover:text-slate-400 iosevka' href={emailLink}>contact@lonewolf-solutions.pl</a>
    </footer>

  </div>
}

export default App
