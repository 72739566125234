const HeaderLink = ({children, to}: React.PropsWithChildren<{to: string}>) => {
  return <a href={to}>{children}</a>
}

export const Header = () => {
  return (
    <div className="flex flex-col md:flex-row w-full gap-10 justify-between items-center md:items-end mb-4">
      <div className='flex sm:flex-row flex-col items-end cursor-pointer'>
        <a href="/">
          <img 
            src={"/assets/logo-transparent.svg"} 
            alt="logo" 
            height="110" 
            width="240" 
          />
        </a>
        <a href="/">
          <img 
            src={"/assets/text-transparent.svg"} 
            alt="logo" 
            height="110" 
            width="240"
          />
        </a>
      </div>

      <div className='inline-flex w-full md:w-auto gap-10 iosevka text-xl justify-around md:justify-start'>
        <HeaderLink to='/solutions'>Solutions</HeaderLink>
        <HeaderLink to='/contact'>Contact</HeaderLink>
      </div>
    </div>
  )
}
