const emailLink = "mailto:contact@lonewolf-solutions.pl"

const ReactLink = () => <a href="https://reactjs.org">React</a>
const ReactQueryLink = () => <a href="https://react-query.tanstack.com/">React Query</a>

const DockerLink = () => <a href="https://www.docker.com">Docker</a>

const NodeJSLink = () => <a href="https://nodejs.org">NodeJS</a>
const NextJSLink = () => <a href="https://nextjs.org">NextJS</a>

const HasuraLink = () => <a href="https://hasura.io">Hasura</a>
const ApolloLink = () => <a href="https://www.apollographql.com/">Apollo</a>

const TailwindCssLink = () => <a href="https://tailwindcss.com/">Tailwind CSS</a>
const MaterialUILink = () => <a href="https://v4.mui.com/">Material UI</a>

const OAuthLink = () => <a href="https://oauth.net/">OAuth</a>
const Auth0Link = () => <a href="https://auth0.net/">Auth0</a>

const EmphieLink = () => <a href="https://emphie.com">Emphie Solutions</a>

const PartnerClause = () => <p className="font-bold">Delivered thanks to our long-term partner <EmphieLink />.</p>

export {
  ApolloLink, 
  Auth0Link, 
  DockerLink, 
  emailLink, 
  EmphieLink, 
  HasuraLink, 
  MaterialUILink,
  NextJSLink, 
  NodeJSLink, 
  OAuthLink, 
  PartnerClause,
  ReactLink, 
  ReactQueryLink, 
  TailwindCssLink,
}
