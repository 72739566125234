import { 
  ApolloLink, 
  Auth0Link, 
  DockerLink, 
  HasuraLink, 
  MaterialUILink, 
  NextJSLink, 
  NodeJSLink, 
  OAuthLink, 
  PartnerClause, 
  ReactLink, 
  ReactQueryLink, 
  TailwindCssLink 
} from "utils"

const Solutions = () => {
  return (
    <article>

      {/* Brandtech */}

      <h3>Brandtech.io</h3>
      <p>First iteration of MVP. The product we supported was a marketing platform with extensive AI made to recognize similar images, videos and such. The platform&apos;s goal was to record which company/sub-company has IP rights to certain art, picture or a movie to make it easy to ask for licensing and estimate value of such picture, i.e. by attaching marketing campaigns results and by that, the amount of money it earned. We&apos;ve been working on React based front-end and deployment in Google Cloud.</p>
      <PartnerClause /> 
      <p>
        References: <a href="https://www.linkedin.com/in/jeremystanton">Jeremy Stanton</a>
        <br />
        Tech stack: <DockerLink />, <ReactLink />, <ReactQueryLink />, <MaterialUILink />, <TailwindCssLink />.
      </p>

      {/* Techtorch */}

      <h3>Techtorch.io</h3>
      <p>Implementation of the Auth0 login page along side with its integration to NextJS solution of the client. I was responsible for architecture behind registration, bind custom events to auth0 hooks and overall integration with the portal designed with cooperation with other Emphie&apos;s employees.</p>

      <PartnerClause /> 

      <p>
        References: <a href="https://www.linkedin.com/in/vmier/">Victor Mier</a>
        <br />
        Tech stack: <DockerLink />, <ReactLink />, <NextJSLink />, <TailwindCssLink />, <NodeJSLink />, <OAuthLink />, <Auth0Link />.
      </p>

      {/* Del'art*/}

      <h3>Del&apos;Art</h3>
      <p>NFT auction house platform of physical art (paintings, sculptures etc.). The goal of the platform was to give that group of artists a possibility in participation in NFT revolution and sell their art on the free market rather than through art galleries. Our role was prepare react frontend, deliver few small on-demand micro services for purposes like authorization s3 image upload and download.</p>
      <PartnerClause /> 
      <p>
        References: <a href="https://www.linkedin.com/in/jeremystanton">Jeremy Stanton</a>
        <br />
        Tech stack: <DockerLink />, <ReactLink />, <HasuraLink />, <ApolloLink />, <TailwindCssLink />, <NodeJSLink />, <OAuthLink />.
      </p>
    </article>

  )
}

export default Solutions
