import { BrowserRouter, Route, Routes } from "react-router-dom"

import Contact from "pages/contact"
import Home from "pages/home"
import Solutions from "pages/solutions"

const Router = () => {
  return <BrowserRouter>
    <Routes>
      <Route
        path='/' element={<Home />}>
      </Route>
      <Route
        path='/solutions' element={<Solutions />}>
      </Route>
      <Route
        path='/contact' element={<Contact />}>
      </Route>
    </Routes>
  </BrowserRouter>
}

export default Router
