export default function Home() {
  return (
    <article>
      <p>Lonewolf Solutions delivers IT services in multiple areas of product development process including front-end, backend, deployment and operations. 
Over the years we were able to gain multiple clients in various industries we continue to support.
      </p>
      <p>Our main focus are TypeScript/JavaScript, .NET, graphQL, and Golang solutions. 
But we don&apos;t end there. 
We have many competences in area of Linux Administration, Bash scripting, Docker, Kubernetes.
      </p>
      <p>Feel free to reach us if you need support!</p>
    </article>
  )
}
